<template>
  <div>
    <GenericTable
      ref="lista"
      name="tipo de material"
      title="Tipos de Materiais"
      add-route="tipoMaterialAdicionar"
      edit-route="tipoMaterialEditar"
      idKey="id_tipo_material"
      :nofilters="true"
      :cols="['Nome', 'Descrição']"
      :cols-name="['nome', 'descricao']"
      :cols-map="i => [i.nome, i.descricao]"
      :route="tipoMaterialRoute"
      :permissionsToWrite="['rw_material']"
      :hasPagination="true"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      tipoMaterialRoute: 'tipoMaterial',
    };
  },
};
</script>
